import { useNavigate } from "react-router-dom";

function Hospitals() {
    const navigate = useNavigate()
    const cities = [
        {id: 43, name: 'مكة المكرمة'},
        {id: 13,  name: 'المدينة المنورة'},
        {id: 46,  name: 'الرياض'},
        {id: 36,  name: 'جدة'},
        {id: 8, name: 'الدمام'},
      ];
      const handleSearch = (city) => {
        const query = new URLSearchParams({
          cityId: city.id || '',
          cityName: city.name || ''
        }).toString();
        navigate(`/hospitals?${query}`);
      };
    
        return ( 
        <div className="text-center hidden md:block">
            <p className="avenir-heavy text-white text-xl my-3">المستشفيات</p>
            <div className="grid grid-cols-2 avenir-book text-white gap-12">
            <ul className="flex justify-start items-center gap-3 flex-col w-full text-sm">
                <li key={cities[0].id} onClick={()=>handleSearch(cities[0])} className="whitespace-nowrap cursor-pointer">مستشفيات {cities[0].name}</li>
                <li key={cities[1].id} onClick={()=>handleSearch(cities[1])} className="whitespace-nowrap cursor-pointer">مستشفيات {cities[1].name}</li>
            </ul>
            <ul className="flex-box-center gap-3 flex-col w-full text-sm">
            <li key={cities[2].id} onClick={()=>handleSearch(cities[2])} className="whitespace-nowrap cursor-pointer">مستشفيات {cities[2].name}</li>
            <li key={cities[3].id} onClick={()=>handleSearch(cities[3])} className="whitespace-nowrap cursor-pointer">مستشفيات {cities[3].name}</li>
            <li key={cities[4].id} onClick={()=>handleSearch(cities[4])} className="whitespace-nowrap cursor-pointer">مستشفيات {cities[4].name}</li>
            </ul>
            </div>
        </div>
     );
}

export default Hospitals;