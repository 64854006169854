function CopyRight() {
    return ( 
        <p className="bg-[--main-color-orange] avenir-heavy text-black text-center py-3 text-sm relative">
            جميع الحقوق محفوظة 2025 
            {/* <span className="absolute text-[10px] left-[20px]">v0.5.5</span> */}
        </p>
    );
}

export default CopyRight;
