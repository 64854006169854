// about-us
// "/privacy-policy"
// "/terms-and-conditions
import { Link } from "react-router-dom";

function Contacts() {
    return ( 
        <div className="text-center">
            <p className="avenir-heavy text-white text-xl my-3">
                سياسة الخصوصية
            </p>
            <ul className="flex-box-center gap-3 flex-col avenir-book text-white">
                <li>
                    {/* <a href='tel:+966245782896'  rel="noreferrer" dir="ltr">
                        +966 245782896
                    </a> */}
                    <Link to="/about-us" className="text-white">
                        من نحن
                    </Link>
                </li>
                <li>
                    {/* <a href='mailto:info@track.com' rel="noreferrer" target="_blank" >
                        info@track.com
                    </a> */}
                    <Link to="/privacy-policy" className="text-white">
                        سياسة الخصوصية
                    </Link>
                </li>
                <li>
                    {/* <a href='tel:+966415782456' rel="noreferrer"  dir="ltr">
                        +966 415782456
                    </a> */}
                    <Link to="/terms-and-conditions" className="text-white">
                        الشروط والأحكام
                    </Link>
                </li>
            </ul>
        </div>
     );
}

export default Contacts;