import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Footer from './components/Home/components/footer/Footer';
import CopyRight from './components/Home/components/CopyRight';
import Loader from './components/admin/hospital/components/Loader'; // Import the Loader component
import RedirectIfAuthenticated from './routes/user/RedirectIfAuthenticated'; // Import the HOC
import RequiredRoles from './routes/roles/RequiredRoles';
import AdminPermissions from './components/admin/AdminTrackSuper/components/settings/components/AdminPermissions';
import TrackClientPermissions from './components/admin/AdminTrackSuper/components/settings/components/TrackClientPermissions';
import ModetatorsPermissionsTrack from './components/admin/AdminTrackSuper/components/settings/components/ModetatorsPermissionsTrack';

// Import pages/components lazily
const Home = React.lazy(() => import('./Pages/Home'));
const SigninHospitals = React.lazy(() => import('./Pages/SigninHospitals'));
const SigninMember = React.lazy(() => import('./Pages/SigninMember'));
const SignFormOtp = React.lazy(() => import('./Pages/SignFormOtp'));
const MapsSearch = React.lazy(() => import('./Pages/MapsSearch'));
const Search = React.lazy(() => import('./components/Home/components/search/Search'));
const SignupHospitals = React.lazy(() => import('./Pages/SingupHospitals')); 
const SignupMembers = React.lazy(() => import('./Pages/SignupMembers'));
const Hospitals = React.lazy(() => import('./Pages/Hospitals'));
const HospitalsCities = React.lazy(() => import('./Pages/HospitalsCities'));
const Hospital = React.lazy(() => import('./Pages/Hospital'));
const AboutUsHospital = React.lazy(() => import('./components/hospital/components/AboutUsHospital'));
const MajorsHospital = React.lazy(() => import('./components/hospital/components/MajorsHospital'));
const InsuranceCompanies = React.lazy(() => import('./components/hospital/components/InsuranceCompanies'));
const ClinicsHome = React.lazy(() => import('./Pages/ClinicsHome'));
const DashboardHospital = React.lazy(() => import('./components/admin/dashboard/DashboardHospital'));
const DashboardTrack = React.lazy(() => import('./components/admin/dashboard/DashboardTrack'));
const DashboardTrackAdminHome = React.lazy(() => import('./components/admin/dashboard/components/Dashhome/DashboardTrackAdminHome'));
const DashboardHospitalHome = React.lazy(() => import('./components/admin/dashboard/components/Dashhome/DashboardHospitalHome'));
const ClinicsHospital = React.lazy(() => import('./Pages/ClinicsHospital'));
const ErrorNotFound = React.lazy(() => import('./components/errors/ErrorNotFound'));
const AddHospital = React.lazy(() => import('./components/admin/AdminTrackSuper/components/AddHospital'));
const AddCompany = React.lazy(() => import('./components/admin/AdminTrackSuper/components/AddCompany'));
const AddNewDeal = React.lazy(() => import('./components/admin/hospital/components/hospitalAdminInfo/components/AddNewDeal'));
const EditDeal = React.lazy(() => import('./components/admin/hospital/components/hospitalAdminInfo/components/EditDeal'));
const HospitalAdmin = React.lazy(() => import('./components/admin/hospital/Pages/HospitalAdmin'));
const HospitalSettings = React.lazy(()=> import('./components/admin/hospital/Pages/HospitalSettings'));
const HospitalPermissions = React.lazy(()=>import('./components/admin/hospital/components/settings/HospitalPermissions'))
const ModeratoresPermission = React.lazy(()=>import('./components/admin/hospital/components/settings/ModeratoresPermission'))
const HospitalInfo = React.lazy(() => import('./components/admin/AdminTrackSuper/components/TrackHospital/HospitalInfo'));
const AdminLogin = React.lazy(() => import('./components/Sign/AdminLogin'));
const AdminLoginForgot = React.lazy(() => import('./components/Sign/AdminLoginForgot'));
const DealsPage = React.lazy(() => import('./Pages/DealsPage'));
const DealInfoPage = React.lazy(() => import('./Pages/DealInfoPage'));
const ResetPassword = React.lazy(() => import('./components/admin/hospital/components/ResetPasswordHospital'));
const Requests = React.lazy(() => import('./components/admin/AdminTrackSuper/Pages/Requests'));
const RequestsHospitals = React.lazy(() => import('./components/admin/AdminTrackSuper/components/RequestsHospitals'));
const RequestsCompanies = React.lazy(() => import('./components/admin/AdminTrackSuper/components/RequestsCompanies'));
const RequestsDeals = React.lazy(() => import('./components/admin/AdminTrackSuper/components/RequestsDeals'));
const TrackTeams = React.lazy(() => import('./components/admin/AdminTrackSuper/Pages/TrackTeams'));
const HospitalTeam = React.lazy(() => import('./components/admin/hospital/Pages/HospitalTeam'));
const TrackAds = React.lazy(() => import('./components/admin/AdminTrackSuper/Pages/TrackAds'));
const HospitalRequest = React.lazy(() => import('./components/admin/hospital/Pages/HospitalRequest'));
const HospitalsTrack = React.lazy(() => import('./components/admin/AdminTrackSuper/Pages/HospitalsTrack'));
const SettingsTrack = React.lazy(() => import('./components/admin/AdminTrackSuper/Pages/SettingsTrack'));
const UserProfile = React.lazy(() => import('./Pages/UserProfile'));
const UserInfo = React.lazy(()=> import('./components/profile_user/components/UserInfo'));
const UserRequests = React.lazy(()=> import('./components/profile_user/components/UserRequests'));

const AboutUs = React.lazy(() => import('./Pages/AboutUs'));
const PrivacyPolicy = React.lazy(() => import('./Pages/PrivacyPolicy'));
const TermsAndConditions = React.lazy(() => import('./Pages/TermsAndConditions'));

// Constant Of ROLES
const ROLES = {
  Track_Admin: 'Track Super Admin',
  Track_Moderator: 'Track Moderator',
  Hospital_Client: 'Client Admin',
  Client_Moderator: 'Client Moderator',
  User_Role:'End User'
}
function Layout({ children }) {
  const location = useLocation();

  return (
    <>
      {!location.pathname.includes('/admin') && <Navbar />}
      <main>{children}</main>
      {location.pathname !== '/signin-hospitals'
        && location.pathname !== '/signin-members'
        && location.pathname !== '/signup-members'
        && location.pathname !== '/signup-hospitals'
        && location.pathname !== '/otp-code'
        && !location.pathname.includes('/admin') && (
          <>
            <Footer />
            <CopyRight />
          </>
        )}
    </>
  );
}

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="/about-us" element={<Layout><AboutUs /></Layout>} />
          <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
          <Route path="/terms-and-conditions" element={<Layout><TermsAndConditions /></Layout>} />
          <Route path="/search" element={<Layout><Search /></Layout>} />
          <Route path="/maps" element={<Layout><MapsSearch /></Layout>} />
          <Route path="/hospitals" element={<Layout><Hospitals /></Layout>} />
          <Route path="/clinics-home" element={<Layout><ClinicsHome /></Layout>} />
          <Route path="/clinics-hospitals" element={<Layout><ClinicsHospital /></Layout>} />
          <Route path="/hospitals-cities" element={<Layout><HospitalsCities /></Layout>} />
          <Route path="/deals-offers" element={<Layout><DealsPage /></Layout>} />
          <Route path="/deal-info/:id" element={<Layout><DealInfoPage /></Layout>} />
          <Route path="*" element={<ErrorNotFound />} />
          <Route path="/hospital-profile/:id" element={<Layout><Hospital /></Layout>}>
            <Route index  element={<MajorsHospital />} />
            <Route index path="about-us" element={<AboutUsHospital />} />
            <Route path="majors" element={<MajorsHospital />} />
            <Route path="insurance-companies" element={<InsuranceCompanies />} />
          </Route>

          <Route 
            path="/signin-hospitals" 
            element={
              <RedirectIfAuthenticated>
                <Layout><SigninHospitals /></Layout>
              </RedirectIfAuthenticated>
            } 
          />
          <Route 
            path="/signin-members" 
            element={
              <RedirectIfAuthenticated>
                <Layout><SigninMember /></Layout>
              </RedirectIfAuthenticated>
            } 
          />
          <Route 
            path="/signup-hospitals" 
            element={
              <RedirectIfAuthenticated>
                <Layout><SignupHospitals /></Layout>
              </RedirectIfAuthenticated>
            } 
          />
          <Route path="/admin-login" element={<Layout><AdminLogin /></Layout>} />
          <Route path="/admin-forgot-password" element={<Layout><AdminLoginForgot /></Layout>} />
          <Route 
            path="/signup-members" 
            element={
              <RedirectIfAuthenticated>
                <Layout><SignupMembers /></Layout>
              </RedirectIfAuthenticated>
            } 
          />
          <Route path="/otp-code" element={
              <RedirectIfAuthenticated>
                <Layout><SignFormOtp /></Layout>
              </RedirectIfAuthenticated>
            } 
          />
          <Route path="/reset-password/:token" element={<Layout><ResetPassword /></Layout>} />
          {/* Public Routes */}

          {/* Private Routes User */}
          <Route path="/user-profile" element={<Layout><UserProfile /></Layout>} >
            <Route index element={<UserInfo />} />
            <Route path='/user-profile/requests' element={<UserRequests />} />
          </Route>
          {/* Private Routes User */}

          {/* Private Routes Admin  */}
          <Route element={<RequiredRoles allowedRoles={[ROLES.Track_Admin,ROLES.Track_Moderator]}/>}>
          <Route path="/admin/track-dashboard" element={<Layout><DashboardTrack /></Layout>}>
            <Route index element={<DashboardTrackAdminHome />} />
            <Route path="requests" element={<Requests />}>
              <Route index element={<RequestsHospitals />} />
              <Route path="hospitals" element={<RequestsHospitals />} />
              <Route path="companies" element={<RequestsCompanies />} />
              <Route path="deals-offers" element={<RequestsDeals />} />
            </Route>
            <Route path="add-hospital" element={<AddHospital />} />
            <Route path="add-company" element={<AddCompany />} />
            <Route path="hospitals-track" element={<HospitalsTrack />} />
            <Route path="hospital-info/:id" element={<HospitalInfo />} />
            <Route path="settings" element={<SettingsTrack />} >
            <Route index  element={<AdminPermissions />} />
            <Route path='track-permissions'  element={<AdminPermissions />} />
            <Route path='client-permissions'  element={<TrackClientPermissions />} />
            <Route path='moderators-permissions'  element={<ModetatorsPermissionsTrack />} />
            </Route>
            <Route path="track-team" element={<TrackTeams />} />
            <Route path="track-ads" element={<TrackAds />} />
            </Route>
          </Route>

          <Route element={<RequiredRoles allowedRoles={[ROLES.Hospital_Client,ROLES.Client_Moderator]}/>}>
          <Route path="/admin/hospital-dashboard" element={<Layout><DashboardHospital /></Layout>}>
          <Route index element={<DashboardHospitalHome/>} />
            <Route path="clinics-request" element={<HospitalRequest />} />
            <Route path="hospital-info-admin" element={<HospitalAdmin />} />
            <Route path="hospital-team" element={<HospitalTeam />} />
            <Route path="add-deal" element={<AddNewDeal />} />
            <Route path="edit-deal/:id" element={<EditDeal />} />
            <Route path="settings-hospital" element={<HospitalSettings />} >
            <Route index element={<HospitalPermissions/>} />
            <Route path="hospital-permissions" element={<HospitalPermissions/>} />
            <Route path="moderators-permissions" element={<ModeratoresPermission/>} />
            </Route>
          </Route>
          </Route>
          {/* Private Routes Admin  */}

        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
